<template>
  <div>
    <header-admin-pages
            title="Дополнительные материалы"
            :counter="getAdditionalMaterial.length"
            :button="headerButton"/>
    <table>
      <tr>
        <td>Заголовок</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="item in getAdditionalMaterial" :key="`itemBlock${item.id}`">
        <td>{{ item.title }}</td>
        <td class="drop-icon">
          <drop-list @clickItem="clickItem"
                     :id-item="item.id"
                     :edit-link="`/admin/additional_materials/${item.id}/edit`"/>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'

export default {
  name: 'AdditionalMaterial',

  data () {
    return {
      headerButton: {
        title: 'Добавить блок дополнительного материала',
        link: '/admin/additional_materials/create'
      }
    }
  },

  created () {
    this.$store.dispatch('setAdditionalMaterial')
  },

  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить дополнительный материал?')
        if (consent) {
          this.$store.dispatch('deleteAdditionalMaterial', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
    }
  },

  computed: {
    getAdditionalMaterial () {
      return this.$store.getters.getAdditionalMaterial
    }
  },

  components: {
    DropList,
    HeaderAdminPages
  }
}
</script>

<style scoped lang="scss">
  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
